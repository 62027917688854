<script setup lang="ts">
  const { allRegions: regions } = await useRegions()
</script>
<template>
      <SectionHeader title="Book Your Party" desc="Select Your Location To Book" />
      <section class="container mx-auto px-4">
       
        <!-- <div class="flex flex-wrap -mx-4 justify-center">
          <RegionCard v-for="(region, index) in regions" :key="index" v-bind="region" />
        </div> -->

        <div class="flex flex-col md:grid md:grid-cols-3 gap-3">
          <RegionCard v-for="(region, index) in regions" :key="index" v-bind="region" />
    </div>
  </section>
</template>
<style scoped></style>
